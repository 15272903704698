import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import ApplicationContext from "../../context/ApplicationContext";

import { Button, Modal } from "react-bootstrap";

import { IProduct } from "../../types/IProduct";
import { IInstruction } from "../../types/IInstruction";
import { Table } from "semantic-ui-react";
import { ITableData } from "../../types/ITableData";
import { GenerateGUID } from "../../helpers/Helper";
import { IInstructionViewProps } from "./IInstructionViewProps";
import { IReceiptRow } from "../../types/IReceiptRow";

export const InstructionView: React.FC<IInstructionViewProps> = (
  props: IInstructionViewProps
): JSX.Element => {
  const defaultInstructions: IInstruction[] = [];

  const context = useContext(ApplicationContext);

  const [instructions, setInstructions] = React.useState(defaultInstructions);

  const defaultTableData: ITableData[] = [];
  const [tableData, setTableData] = React.useState(defaultTableData);

  const [refresh, setRefresh] = React.useState("");

  const [selectedInstructionUuid, SetSelectedInstructionUuid] = useState("");
  const [negative, setNegative] = React.useState(false);

  const customStyle = context.Configuration.getSettings().styleSettings;

  function populate() {
    const row = context.Receipt.getRows().filter(
      (r) => r.uuid === props.rowUuid
    );

    if (row === undefined || row[0] === undefined) {
      return;
    }

    const pId = row[0].product.id;
    const allInstructions = context.BranchMenu.getMenu().instructions;

    let pInstructions: IInstruction[] = [];
    for (let i = 0; i < allInstructions.length; i++) {
      if (allInstructions[i].productId == pId) {
        pInstructions.push(allInstructions[i]);
      }
    }
    setInstructions(pInstructions);

    //get already selected instructions
    const currentInstructions = row[0].instructions;
    let data: ITableData[] = [];
    for (let i = 0; i < currentInstructions.length; i++) {
      const d: ITableData = {
        id: currentInstructions[i].id,
        description: currentInstructions[i].description,
        price: currentInstructions[i].price,
        variationId: 0,
        extraId: 0,
        commonId: 0,
        notes: "",
        instructionId: currentInstructions[i].id,
        uuid: GenerateGUID(),
      };
      data.push(d);
    }

    setTableData(data);

    setRefresh(GenerateGUID());
  }

  useEffect(() => {
    populate();
  }, [props.rowUuid]);

  function onInstructionClick(instruction: IInstruction) {
    const description = negative
      ? "no " + instruction.description
      : instruction.description;

    const price = negative ? 0 : instruction.price;

    const d: ITableData = {
      id: instruction.id,
      description: description,
      price: price,
      variationId: 0,
      extraId: 0,
      commonId: 0,
      notes: "",
      instructionId: instruction.id,
      uuid: GenerateGUID(),
    };
    let data = tableData;
    data.push(d);
    setTableData(data);
    setNegative(false);
    setRefresh(GenerateGUID());
  }

  function onInstructionNegativeClick() {
    if (negative) {
      setNegative(false);
    } else {
      setNegative(true);
    }
  }

  function onInstructionRemoveClick() {
    const data: ITableData[] = tableData;

    let idx = -1;

    for (let i = 0; i < data.length; i++) {
      if (data[i].uuid === selectedInstructionUuid) {
        idx = i;
      }
    }

    data.splice(idx, 1);
    setTableData(data);
    setRefresh(GenerateGUID());
  }

  function isSelected(data: ITableData) {
    if (data.uuid === selectedInstructionUuid) {
      return true;
    }

    return false;
  }

  function onRowClicked(data: ITableData) {
    SetSelectedInstructionUuid(data.uuid);
  }

  function close() {
    props.close();
  }

  const instructionsView = (): JSX.Element => {
    let commonInstructions: IInstruction[] = [];

    const menu = context.BranchMenu.getMenu();

    if (menu !== undefined) {
      const commonData = menu.instructions.filter((r) => r.isCommon === true);

      if (commonData.length > 0) {
        commonInstructions = commonData;
      }
    }

    return (
      <Modal
        show={props.show}
        onHide={() => close()}
        dialogClassName="my-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>instructions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div>
              <div style={{ padding: "5px" }}>
                <div className="row">
                  <div style={{ width: "59%" }}>
                    <div style={{ height: "50vh" }}>
                      <div
                        style={{
                          height: "50vh",
                          overflowY: "auto",
                        }}
                      >
                        {instructions
                          .sort((a, b) => (a.position > b.position ? 1 : -1))
                          .map((i: IInstruction) => (
                            <Button
                              className="mr-1"
                              variant="outline-primary bt-lg"
                              style={{
                                width: customStyle.menuButtonInstructionWidth,
                                height: customStyle.menuButtonInstructionHeight,
                                fontSize:
                                  customStyle.menuButtonInstructionFontSize,
                                fontWeight: "bolder",
                                //backgroundColor: "lightblue",
                                borderRadius: "10px",
                                padding: "0px",
                              }}
                              onClick={() => onInstructionClick(i)}
                            >
                              {i.description} <br /> £{i.price.toFixed(2)}
                            </Button>
                          ))}
                      </div>
                    </div>
                    <div>
                      <hr></hr>
                    </div>
                    <div
                      style={{
                        height: "15vh",
                        // backgroundColor: "blue",
                        overflowY: "auto",
                      }}
                    >
                      {commonInstructions.map((i: IInstruction) => (
                        <Button
                          className="mr-1"
                          variant="outline-primary bt-lg"
                          style={{
                            width: customStyle.menuButtonInstructionWidth,
                            height: customStyle.menuButtonInstructionHeight,
                            fontSize: customStyle.menuButtonInstructionFontSize,
                            //backgroundColor: "lightblue",
                            fontWeight: "bold",
                            borderRadius: "10px",
                            padding: "10px",
                          }}
                          onClick={() => onInstructionClick(i)}
                        >
                          {i.description} <br /> £{i.price.toFixed(2)}
                        </Button>
                      ))}
                    </div>
                  </div>
                  <div style={{ padding: "3px" }}></div>
                  <div style={{ width: "39%" }}>
                    <div>
                      <div style={{ paddingBottom: 10 }}>
                        <button
                          type="button"
                          className={
                            negative
                              ? "btn btn-outline-primary btn-lg active"
                              : "btn btn-outline-primary btn-lg"
                          }
                          onClick={onInstructionNegativeClick}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            fill="currentColor"
                            className="bi bi-clipboard-minus"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5.5 9.5A.5.5 0 0 1 6 9h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5"
                            />
                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                          </svg>
                        </button>{" "}
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-lg"
                          onClick={onInstructionRemoveClick}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            fill="currentColor"
                            className="bi bi-clipboard-x"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708"
                            />
                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                          </svg>
                        </button>
                      </div>
                      <div style={{ overflowY: "auto", maxHeight: "55vh" }}>
                        <Table selectable color={"blue"}>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Description</Table.HeaderCell>
                              <Table.HeaderCell>Price</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {tableData.map((data) => (
                              <Table.Row
                                active={isSelected(data)}
                                onClick={() => {
                                  onRowClicked(data);
                                }}
                              >
                                <Table.Cell>{data.description}</Table.Cell>
                                <Table.Cell>{data.price.toFixed(2)}</Table.Cell>
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>
                        {/* <div ref={moveToEndVariationsRef}></div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer className="modal-footer justify-content-between">
          <div className="mr-auto"></div>
          <div>
            <Button
              variant="success"
              className="modal-padding"
              onClick={() => onInstructionUpdateClick()}
              style={{ height: 50, width: 150, fontSize: 16 }}
            >
              Update Order
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  function onInstructionUpdateClick() {
    const selectedInstructions = tableData;
    const allInstructions = context.BranchMenu.getMenu().instructions;
    let instructions: IInstruction[] = [];

    for (let i = 0; i < selectedInstructions.length; i++) {
      for (let j = 0; j < allInstructions.length; j++) {
        if (allInstructions[j].id == selectedInstructions[i].instructionId) {
          let clone: IInstruction = JSON.parse(
            JSON.stringify(selectedInstructions[i])
          );

          clone.description = selectedInstructions[i].description;
          clone.price = selectedInstructions[i].price;
          instructions.push(clone);
        }
      }
    }

    context.Receipt.addInstructionsByUuid(props.rowUuid, instructions);
    close();
  }

  return (
    <>
      <>{instructionsView()}</>
    </>
  );
};
