import { GenerateGUID } from "../../helpers/Helper";
import { ICustomer } from "../../types/ICustomer";
import { ITelephone } from "../../types/ITelephone";
import { ICallData } from "./ICallData";

let data: ITelephone[] = [];
let uuid: string = "";

// const c: ICustomer = {
//   id: -1,
//   name: "smith",
//   addressLines: ["12 leeds road"],
//   telephone: "0779780259",
//   postcode: "BD38QJ",
//   endComment: "",
//   uuid: "cdce24f1-6dba-4a5c-b91b-b2ec9cfdd93c",
// };

// const t: ITelephone = {
//   id: 0,
//   uuid: "",
//   contact: "",
//   customer: c,
//   createdAt: new Date(),
// };

// data.push(t);

export class CallData implements ICallData {
  public getData(): ITelephone[] {
    return data;
  }
  public setData(data: ITelephone[]) {
    let deepCopy: ITelephone[] = JSON.parse(JSON.stringify(data));
    data = deepCopy;
  }
  public refresh() {
    uuid = GenerateGUID();
  }
  public refreshRequired(): string {
    return uuid;
  }
}
