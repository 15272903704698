import React, { useContext, useEffect } from "react";
import ApplicationContext from "../../../context/ApplicationContext";
import { Button, Form, Modal } from "react-bootstrap";
import "./style.css";
import { ISettingsProps } from "./ISettingsProps";
import { IPrinter } from "../../../types/IPrinter";
import { Dropdown, DropdownItemProps } from "semantic-ui-react";

const defaultPrinter: IPrinter = {
  id: 0,
  appUrl: "",
  env: "",
  communication: "",
  defaultUrl: "",
  ip: "",
  port: "",
  noOfPrints: 0,
};

export const SettingsModal: React.FC<ISettingsProps> = (
  props: ISettingsProps
): JSX.Element => {
  const context = useContext(ApplicationContext);

  const [data, setData] = React.useState<DropdownItemProps[]>([]);
  const [noOfPrints, setNoOfPrints] = React.useState<DropdownItemProps[]>([]);

  function printerSelected(event: any, { value }: any) {
    window.localStorage.setItem("printer", value);
  }

  function noOfPrintsSelected(event: any, { value }: any) {
    window.localStorage.setItem("noOfPrinters", value.toString());
  }

  useEffect(() => {
    let ddPrinters: DropdownItemProps[] = [];
    let prints: DropdownItemProps[] = [];
    const printers = context.Configuration.getSettings().printers;

    for (let i = 0; i < printers.length; i++) {
      const element = printers[i];
      const d: DropdownItemProps = {
        text:
          element.id +
          "|" +
          element.appUrl +
          "|" +
          element.env +
          "|" +
          element.communication +
          "|" +
          element.ip +
          "|" +
          element.port,
        value: JSON.stringify(element),
      };
      ddPrinters.push(d);
    }

    setData(ddPrinters);

    for (let i = 0; i < 10; i++) {
      const d: DropdownItemProps = {
        text: i.toString(),
        value: i,
      };
      prints.push(d);
    }

    setNoOfPrints(prints);
  }, []);

  function updateUsername(value: string) {
    window.localStorage.setItem("username", value.toString());
  }

  function updatePassword(value: string) {
    window.localStorage.setItem("password", value.toString());
  }

  const settingsModal = (): JSX.Element => {
    return (
      <>
        <Modal show={props.show} onHide={() => props.close()} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Printer</label>
              <div className="col-sm-10">
                <Dropdown
                  placeholder="Printer"
                  search
                  selection
                  options={data}
                  onChange={printerSelected}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">No of prints</label>
              <div className="col-sm-10">
                <Dropdown
                  placeholder="No of Prints"
                  search
                  selection
                  options={noOfPrints}
                  onChange={noOfPrintsSelected}
                />
              </div>
            </div>
            <hr />
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Username</label>
              <div style={{ paddingLeft: 10 }}>
                <Form.Control
                  width={200}
                  aria-describedby="basic-addon1"
                  style={{ fontSize: "16px", width: "100%" }}
                  onChange={(e) => updateUsername(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Password</label>
              <div style={{ paddingLeft: 10 }}>
                <Form.Control
                  width={200}
                  aria-describedby="basic-addon1"
                  style={{ fontSize: "16px", width: "100%" }}
                  onChange={(e) => updatePassword(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <Button
              variant="primary"
              style={{
                width: 100,
                height: 50,
                fontSize: "16px",
                fontWeight: "bolder",
              }}
              onClick={() => admin()}
            >
              Admin
            </Button>
            <Button
              variant="success"
              style={{
                width: 150,
                height: 50,
                fontSize: "16px",
                fontWeight: "bolder",
              }}
              onClick={() => props.close()}
            >
              Save & Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  function admin() {
    let url = "https://eposadmin.aleaf.co.uk";
    window.location.replace(url);
  }

  return (
    <>
      <>{settingsModal()}</>
    </>
  );
};
