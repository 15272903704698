import axios, { AxiosInstance, AxiosResponse } from "axios";
import { IItem, IReturnItem } from "../types/IItem";
import { ISearch } from "../types/ISearch";
import { ISettings } from "../types/ISettings";
import { IMenu } from "../types/IMenu";
import { IReceipt } from "../types/IReceipt";
import { IReceiptData } from "../types/IReceiptData";
import { ITelephone } from "../types/ITelephone";

// export const API = axios.create({
//   baseURL: "https://eposapi.aleaf.co.uk/",
//   timeout: 10000,
//   headers: {
//     "Content-Type": "application/json",
//   },
//   validateStatus: () => true,
// });

const baseConfig = {
  baseURL: "https://eposapi.aleaf.co.uk/",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer ",
  },
  validateStatus: () => true,
};

export const getSettings = (token: string): Promise<ISettings | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  return axios
    .get("Settings", config)
    .then((response: AxiosResponse<ISettings>) => {
      return response.data;
    })
    .catch(handleError);
};

export const getTelephoneData = (
  token: string
): Promise<ITelephone[] | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  return axios
    .get("Telephone", config)
    .then((response: AxiosResponse<ITelephone[]>) => {
      return response.data;
    })
    .catch(handleError);
};

export const createOrder = (
  data: IReceiptData,
  token: string
): Promise<number | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  return axios
    .post("Receipt/Add", data, config)
    .then((response: AxiosResponse<number>) => {
      return response.data;
    })
    .catch(handleError);
};

function handleError(error: any) {
  console.log("api error @ " + new Date());
  console.log(error);
  console.log(error.response);

  if (error.response) {
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log("Error", error.message);
  }
}
