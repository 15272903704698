import React, { useContext, useEffect } from "react";
import ApplicationContext from "../../../context/ApplicationContext";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { ICommentModal } from "./ICommentModal";
import { GenerateGUID } from "../../../helpers/Helper";

export const CommentModal: React.FC<ICommentModal> = (
  props: ICommentModal
): JSX.Element => {
  const context = useContext(ApplicationContext);

  const [notes, setNotes] = React.useState("");
  const [refresh, setRefresh] = React.useState("");

  function closeModal() {
    props.close(props.notes);
  }

  useEffect(() => {
    setNotes(props.notes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const customerModal = (): JSX.Element => {
    return (
      <>
        <Modal show={props.show} onHide={() => closeModal()} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Notes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                  fontSize: 22,
                }}
              >
                <Form.Control
                  as="textarea"
                  placeholder=""
                  style={{ width: "80%", height: "70px", fontSize: 34 }}
                  autoFocus={true}
                  value={notes}
                />
              </div>
              <br />

              <div
                style={{
                  height: "100%",
                  padding: "0px",
                  marginLeft: "auto",
                  marginRight: "0",
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Keyboard
                  physicalKeyboardHighlightPress={true}
                  physicalKeyboardHighlight={true}
                  onKeyPress={onKeyboardKeyPress}
                />
              </div>
            </>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <div className="row">
              <div className="col">
                <Button
                  className="btn btn-primary btn-lg"
                  onClick={() => clear()}
                >
                  Clear
                </Button>
              </div>
              <div className="col">
                <Button
                  className="btn btn-secondary btn-lg"
                  onClick={() => props.close(props.notes)}
                >
                  Cancel
                </Button>
              </div>
            </div>
            <div className="mr-auto"></div>
            <div>
              <Button
                className="btn btn-success btn-lg"
                onClick={() => takePayment()}
              >
                Add Note
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  function takePayment() {
    props.close(notes);
  }

  function clear() {
    setNotes("");
  }

  function onKeyboardKeyPress(value: any) {
    let newNote = notes;

    if (value === "{bksp}") {
      newNote = newNote.substring(0, newNote.length - 1);
    } else if (value === "{space}") {
      newNote = newNote + " ";
    } else {
      newNote = newNote + value;
    }

    setNotes(newNote);
  }

  return (
    <>
      <>{customerModal()}</>
    </>
  );
};
