import React, { useContext, useEffect } from "react";
import ApplicationContext from "../../../context/ApplicationContext";
import { Button, Modal } from "react-bootstrap";
import "./style.css";

import { Dropdown, DropdownItemProps } from "semantic-ui-react";
import { ITransactionTypeProps } from "./ITransactionTypeProps";

export const TransactionTypeModal: React.FC<ITransactionTypeProps> = (
  props: ITransactionTypeProps
): JSX.Element => {
  const context = useContext(ApplicationContext);

  const [data, setData] = React.useState<DropdownItemProps[]>([]);
  const [noOfPrints, setNoOfPrints] = React.useState<DropdownItemProps[]>([]);

  function printerSelected(event: any, { value }: any) {
    window.localStorage.setItem("printer", value);
  }

  function noOfPrintsSelected(event: any, { value }: any) {
    window.localStorage.setItem("noOfPrinters", value.toString());
  }

  useEffect(() => {
    let ddPrinters: DropdownItemProps[] = [];
    let prints: DropdownItemProps[] = [];
    const printers = context.Configuration.getSettings().printers;

    for (let i = 0; i < printers.length; i++) {
      const element = printers[i];
      const d: DropdownItemProps = {
        text:
          element.id +
          "|" +
          element.appUrl +
          "|" +
          element.env +
          "|" +
          element.communication +
          "|" +
          element.ip +
          "|" +
          element.port,
        value: JSON.stringify(element),
      };
      ddPrinters.push(d);
    }

    setData(ddPrinters);

    for (let i = 0; i < 10; i++) {
      const d: DropdownItemProps = {
        text: i.toString(),
        value: i,
      };
      prints.push(d);
    }

    setNoOfPrints(prints);
  }, []);

  const buttonStyle = {
    width: "150px",
    height: "150px",
    fontSize: "20px",
    fontWeight: "bolder",
    backgroundColor: "purple",
    borderRadius: "30px",
  };

  function setType(value: string) {
    context.Receipt.clear();
    context.Receipt.setTransactionType(value);
    props.close();
  }

  const transactionModal = (): JSX.Element => {
    return (
      <>
        <Modal show={props.show} onHide={() => props.close()} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Transaction Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "30vh",
                }}
              >
                <Button
                  className="mr-1"
                  style={buttonStyle}
                  onClick={() => setType("Eat in")}
                >
                  Eat in
                </Button>
                <Button
                  className="mr-1"
                  style={buttonStyle}
                  onClick={() => setType("Takeout")}
                >
                  Takeout
                </Button>
                <Button
                  className="mr-1"
                  style={buttonStyle}
                  onClick={() => setType("Collection")}
                >
                  Collection
                </Button>
                <Button
                  className="mr-1"
                  style={buttonStyle}
                  onClick={() => setType("Delivery")}
                >
                  Delivery
                </Button>
              </div>
            </>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <Button
              variant="success"
              style={{
                width: 150,
                height: 50,
                fontSize: "16px",
                fontWeight: "bolder",
              }}
              onClick={() => props.close()}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  return (
    <>
      <>{transactionModal()}</>
    </>
  );
};
