import { IBranchSettings } from "../../types/IBranchSettings";
import { IOrganisation } from "../../types/IOrganisation";
import { IPrinter } from "../../types/IPrinter";
import {
  defaultStyleSettings,
  IStyleSettings,
} from "../../types/IStyleSettings";
import { IConfiguration } from "./IConfiguration";

const o: IOrganisation = {
  id: 0,
  name: "",
  addressLines: ["support street"],
  telephone: "",
  endComment: "",
  website: "",
  logo: "",
};

const p: IPrinter = {
  id: 0,
  env: "windows",
  defaultUrl: "https://print.aleaf.co.uk/Default.aspx?app=epos",
  appUrl: "default",
  communication: "standard",
  ip: "192.168.2.5",
  port: "9100",
  noOfPrints: 0,
};

let branchSettings: IBranchSettings = {
  organisation: o,
  printers: [p],
  styleSettings: defaultStyleSettings,
};

export class Configuration implements IConfiguration {
  public setSettings(s: IBranchSettings): void {
    branchSettings = s;
  }
  public getSettings(): IBranchSettings {
    return branchSettings;
  }
}
