import React, { useContext, useEffect } from "react";

import ApplicationContext from "../../context/ApplicationContext";
import { ITelephoneProps } from "./ITelephoneProps";

export const TelephoneManger: React.FC<ITelephoneProps> = (
  props: ITelephoneProps
): JSX.Element => {
  const context = useContext(ApplicationContext);

  function update() {
    //get the data from api call and store in context
    const test = "i get here";

    //context.CallData.setData([]);
  }

  useEffect(() => {
    update();
  }, [context.CallData.refreshRequired()]);

  const telephone = (): JSX.Element => {
    return <></>;
  };

  return <div>{telephone()}</div>;
};
