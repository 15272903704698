import React, { useContext, useEffect, useRef, useState } from "react";
import { IReceiptProps } from "./IReceiptProps";
import ApplicationContext from "../../context/ApplicationContext";
import { Button, Modal } from "react-bootstrap";
import { Container, Table } from "semantic-ui-react";
import { IReceiptRow } from "../../types/IReceiptRow";
import "react-simple-keyboard/build/css/index.css";
import { CustomerModal } from "./dialogs/CustomerModal";
import { ICustomerProps } from "./dialogs/ICustomerProps";
import { SimpleCustomerModal } from "./dialogs/SimpleCustomerModal";
import { ITotalProps } from "./dialogs/ITotalProps";
import { TotalModal } from "./dialogs/TotalModal";

import { IProduct } from "../../types/IProduct";

import "./style.css";
import { ICommentModal } from "./dialogs/ICommentModal";
import { CommentModal } from "./dialogs/CommentModal";
import { ISettingsProps } from "./dialogs/ISettingsProps";
import { SettingsModal } from "./dialogs/SettingsModal";
import { TransactionTypeModal } from "./dialogs/TransactionTypeModal";
import { GenerateGUID } from "../../helpers/Helper";
import { IInstructionViewProps, InstructionView } from "../instruction";
import { QtyModal } from "./dialogs/QtyModal";
import { IQtyProps } from "./dialogs/IQtyProps";

export const ReceiptManager: React.FC<IReceiptProps> = (
  props: IReceiptProps
): JSX.Element => {
  const defaultProduct: IProduct = {
    id: 0,
    uuid: "",
    parentId: 0,
    description: "",
    imageUrl: "",
    position: 0,
    isCategory: false,
    price: 0,
    qty: 0,
    textColour: "",
    buttonColour: "",
    zone: 0,
    barcode: "",
  };

  const defaultReceiptRow: IReceiptRow = {
    id: 0,
    product: defaultProduct,
    description: [],
    variations: [],
    extras: [],
    commonOptions: [],
    notes: "",
    instructions: [],
    price: 0,
    qty: 0,
    isDiscount: false,
    options: [],
    uuid: "",
  };

  const context = useContext(ApplicationContext);

  const [showOptions, setShowOptions] = React.useState(false);

  const [showCustomerModal, setShowCustomerModal] = React.useState(false);
  const [simpleCustomerModal, setSimpleCustomerModal] = React.useState(false);
  const [showTotalModal, setShowTotalModal] = React.useState(false);
  const [showCommentModal, setShowCommentModal] = React.useState(false);
  const [showSettings, setShowSettings] = React.useState(false);
  const [showTransactionType, setShowTransactionType] = React.useState(false);
  const [showQtyModal, setShowQtyModal] = React.useState(false);

  const moveToEndRef = useRef<null | HTMLDivElement>(null);

  const [receiptRows, setReceiptRows] = React.useState<IReceiptRow[]>([]);
  const [selectedRowId, setSelectedRowId] = React.useState(Number);
  const [toPay, setToPay] = React.useState(Number);
  const [showInstructions, setShowInstructions] = React.useState(false);
  const [notes, setNotes] = React.useState("");

  const [refresh, setRefresh] = React.useState("");

  const [vWidth, setWidth] = useState(window.innerWidth);
  const [vHeight, setHeight] = useState(window.innerHeight);

  const [selectedRow, setSelectedRow] = React.useState(defaultReceiptRow);

  const customStyle = context.Configuration.getSettings().styleSettings;

  function closeOptions() {
    setShowOptions(false);
    buildReceipt();
  }

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    moveToEndRef.current?.scrollIntoView({ behavior: "smooth" });
  });

  function buildReceipt() {
    const receipts = context.Receipt.getRows();
    setReceiptRows(receipts);
  }

  function update() {
    buildReceipt();
    setToPay(getTotal());
  }

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    update();
  }, [receiptRows.length]);

  function deleteSelectedItem() {
    if (selectedRowId === undefined) {
      return;
    }
    if (selectedRowId === -1) {
      return;
    }

    context.Receipt.delete(selectedRow.uuid);
    closeOptions();
  }

  function voidReceipt() {
    context.Receipt.clear();
    closeOptions();
  }

  const additionalOptions = (): JSX.Element => {
    return (
      <>
        <Modal show={showOptions} onHide={closeOptions}>
          <Modal.Header closeButton>
            <Modal.Title>Options</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col">
                  <button
                    className="btn btn-secondary"
                    style={{ width: "100%", height: "60px", fontSize: "20px" }}
                    type="button"
                    onClick={voidReceipt}
                  >
                    Void
                  </button>
                </div>
              </div>
              <div className="row" style={{ paddingTop: 10 }}>
                <div className="col">
                  <button
                    className="btn btn-danger"
                    style={{ width: "100%", height: "60px", fontSize: "20px" }}
                    type="button"
                    onClick={() => props.transactionTypes()}
                  >
                    Start again
                  </button>
                </div>
              </div>
              <div className="row" style={{ paddingTop: 10 }}>
                <div className="col">
                  <button
                    className="btn btn-primary"
                    style={{ width: "100%", height: "60px", fontSize: "20px" }}
                    type="button"
                    onClick={() => setShowSettings(true)}
                  >
                    Settings
                  </button>
                </div>
              </div>
              <div className="row" style={{ paddingTop: 10 }}>
                <div className="col">
                  <button
                    className="btn btn-info"
                    style={{ width: "100%", height: "60px", fontSize: "20px" }}
                    type="button"
                    onClick={() => setShowTransactionType(true)}
                  >
                    Type
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{
                width: 150,
                height: 50,
                fontSize: "16px",
                fontWeight: "bolder",
              }}
              onClick={closeOptions}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  function isSelected(id: number) {
    if (selectedRowId === id) {
      return true;
    }
    return false;
  }

  function onRowClicked(receiptRow: IReceiptRow) {
    setSelectedRowId(receiptRow.id);
    setSelectedRow(receiptRow);
    setNotes(receiptRow.notes);
  }

  const instructions = (row: IReceiptRow): JSX.Element => {
    return (
      <>
        {row.instructions.map((data) => (
          <>
            <br />
            &emsp;&emsp;{data.description}
            {data.price > 0 ? " (£" + data.price.toFixed(2) + ")" : <></>}
          </>
        ))}
      </>
    );
  };

  const options = (row: IReceiptRow): JSX.Element => {
    return (
      <>
        {row.options.map((data) => (
          <>
            {data.description === "" ? (
              <></>
            ) : (
              <>
                <br />
                &emsp;{data.description}
                {data.price > 0 ? " (£" + data.price.toFixed(2) + ")" : <></>}
              </>
            )}

            {row.variations.map((d) =>
              d.optionId === data.id ? (
                <>
                  <br />
                  &emsp;&emsp;{d.description}
                  {d.price > 0 ? " (£" + d.price.toFixed(2) + ")" : <></>}
                </>
              ) : (
                <></>
              )
            )}
            {row.extras.map((d) =>
              d.optionId === data.id ? (
                <>
                  <br />
                  &emsp;&emsp;{d.description} (
                  {d.price > 0 ? " (£" + d.price.toFixed(2) + ")" : <></>}
                </>
              ) : (
                <></>
              )
            )}
          </>
        ))}
      </>
    );
  };

  const common = (row: IReceiptRow): JSX.Element => {
    return (
      <>
        {row.commonOptions.map((data) => (
          <>
            <br />
            &emsp;&emsp;{data.description}
            {data.price > 0 ? " (£" + data.price.toFixed(2) + ")" : <></>}
          </>
        ))}
      </>
    );
  };

  const comments = (row: IReceiptRow): JSX.Element => {
    return (
      <>
        <br />
        &emsp;&emsp;{row.notes}
      </>
    );
  };

  const subDescription = (row: IReceiptRow): JSX.Element => {
    if (row.description.length === 0) {
      return <></>;
    }

    return (
      <>
        {row.description.map((description) => (
          <>
            <br />
            &emsp;{description}
          </>
        ))}
      </>
    );
  };

  function onClickShowInstructions() {
    setRefresh(GenerateGUID());
    setShowInstructions(true);
  }

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  function onClickShowComment() {
    const test = notes;
    setNotes("");
    sleep(2000);
    setNotes(selectedRow.notes);
    setShowCommentModal(true);
    setRefresh(GenerateGUID());
  }

  function onDeleteRowClick() {
    context.Receipt.delete(selectedRow.uuid);
    setRefresh(GenerateGUID());
  }

  function viewQtyModal(row: IReceiptRow) {
    setSelectedRow(row);
    setShowQtyModal(true);
  }

  const template = () => (
    <>
      <table style={{ width: customStyle.receiptWidth, height: "100%" }}>
        <tbody>
          <tr style={{ width: "100%", height: "100px" }}>
            <td
              style={{
                backgroundColor: "",
                paddingLeft: 10,
              }}
            >
              <button
                type="button"
                className="btn btn-outline-primary btn-lg"
                style={{ width: "70px" }}
                onClick={() => props.mainMenu()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  fill="currentColor"
                  className="bi bi-house"
                  viewBox="0 0 16 16"
                >
                  <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z" />
                </svg>
              </button>{" "}
              <button
                type="button"
                className="btn btn-outline-primary btn-lg"
                style={{ width: "70px" }}
                onClick={onDeleteRowClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  fill="currentColor"
                  className="bi bi-clipboard-x"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708"
                  />
                  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                  <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                </svg>
              </button>{" "}
              <button
                type="button"
                className="btn btn-outline-primary btn-lg"
                style={{ width: "70px" }}
                onClick={() => onClickShowInstructions()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  fill="currentColor"
                  className="bi bi-card-checklist"
                  viewBox="0 0 16 16"
                >
                  <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z" />
                  <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0M7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0" />
                </svg>
              </button>{" "}
              <button
                type="button"
                className="btn btn-outline-primary btn-lg"
                style={{ width: "70px" }}
                onClick={() => onClickShowComment()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  fill="currentColor"
                  className="bi bi-chat-left-text"
                  viewBox="0 0 16 16"
                >
                  <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                  <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6m0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
                </svg>
              </button>
            </td>
          </tr>
          <tr style={{ width: "100%" }}>
            <td
              style={{
                backgroundColor: "",
                verticalAlign: "top",
              }}
            >
              <div
                style={{
                  overflowY: "auto",
                  width: "100%",
                  height: vHeight - 280,
                  padding: 10,
                  fontWeight: "bolder",
                  fontSize: customStyle.receiptFontSize,
                }}
              >
                <Table selectable>
                  <Table.Body>
                    {receiptRows.map((data) => (
                      <Table.Row
                        active={isSelected(data.id)}
                        positive={isSelected(data.id)}
                        onClick={() => {
                          onRowClicked(data);
                        }}
                      >
                        <Table.Cell width={1}>
                          <button
                            type="button"
                            className="btn btn-link"
                            style={{ width: "20px" }}
                            onClick={() => viewQtyModal(data)}
                          >
                            {data.qty}
                          </button>
                        </Table.Cell>

                        <Table.Cell>
                          {data.product.description}
                          {options(data)}
                          {/* {subDescription(data)} */}
                          {instructions(data)}
                          {/* {extras(data)} */}
                          {common(data)}
                          {comments(data)}
                        </Table.Cell>
                        <Table.Cell width={1}>
                          £{(data.qty * data.price).toFixed(2)}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>

                <div ref={moveToEndRef}></div>
              </div>
            </td>
          </tr>
          <tr style={{ width: "100%" }}>
            <td
              style={{
                backgroundColor: "",
                padding: 10,
              }}
            >
              <hr />
              <div className="row">
                <div className="col"></div>
                <div className="col-md-auto"></div>
                <div className="col col-lg-3" style={{ padding: 10 }}>
                  <h3>{"£" + getTotal().toFixed(2)}</h3>
                </div>
              </div>

              <table style={{ width: "100%" }}>
                <tr>
                  <td width={"100%"}>
                    <Button
                      variant="success btn-lg"
                      onClick={() => processComplete()}
                      style={{ height: "60px", width: "100%" }}
                    >
                      Take Payment
                    </Button>
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-lg"
                      onClick={() => setShowOptions(true)}
                      style={{
                        width: "90px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="42"
                        height="42"
                        fill="currentColor"
                        className="bi bi-columns-gap"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6 1v3H1V1zM1 0a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zm14 12v3h-5v-3zm-5-1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM6 8v7H1V8zM1 7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1zm14-6v7h-5V1zm-5-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1z" />
                      </svg>
                    </button>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );

  function processComplete() {
    const transactionType = context.Receipt.getTransactionType();

    setToPay(getTotal());

    if (transactionType === "Collection") {
      setSimpleCustomerModal(true);
    } else if (transactionType === "Delivery") {
      setShowCustomerModal(true);
    } else {
      setShowTotalModal(true);
    }
  }

  function getTotal() {
    let total = 0;
    context.Receipt.getRows().forEach((r) => {
      total += r.qty * r.price;
    });
    return total;
  }

  function closeCustomerModal() {
    setShowCustomerModal(false);
  }

  function closeSimpleCustomerModal() {
    setSimpleCustomerModal(false);
  }

  function toPayment() {
    setShowCustomerModal(false);
    setSimpleCustomerModal(false);
    setShowTotalModal(true);
  }

  const customerModelProps: ICustomerProps = {
    takePayment: toPayment,
    close: closeCustomerModal,
    show: showCustomerModal,
  };

  const simpleCustomerProps: ICustomerProps = {
    takePayment: toPayment,
    close: closeSimpleCustomerModal,
    show: simpleCustomerModal,
  };

  const totalProps: ITotalProps = {
    close: function (): void {
      setShowTotalModal(false);
    },
    show: showTotalModal,
    toPayAmount: toPay.toFixed(2),
    showTransactionTypes: function (): void {
      props.transactionTypes();
    },
  };

  function closeCommentModal(n: string) {
    context.Receipt.setComment(selectedRow.uuid, n);
    setShowCommentModal(false);
    setRefresh(GenerateGUID());
  }

  const commentProps: ICommentModal = {
    show: showCommentModal,
    id: selectedRowId,
    notes: notes,
    close: (n: string) => closeCommentModal(n),
  };

  const settingsProps: ISettingsProps = {
    close: () => setShowSettings(false),
    show: showSettings,
  };

  const transactionTypeProps: ISettingsProps = {
    close: () => setShowTransactionType(false),
    show: showTransactionType,
  };

  const instructionViewProps: IInstructionViewProps = {
    close: () => setShowInstructions(false),
    show: showInstructions,
    rowUuid: selectedRow.uuid,
  };

  const qtyProps: IQtyProps = {
    close: () => setShowQtyModal(false),
    row: selectedRow,
    show: showQtyModal,
  };

  return (
    <>
      <>{template()}</>
      <>{additionalOptions()}</>
      <InstructionView {...instructionViewProps} />

      <CustomerModal {...customerModelProps} />
      <SimpleCustomerModal {...simpleCustomerProps} />
      <TotalModal {...totalProps} />
      <CommentModal {...commentProps} />
      <SettingsModal {...settingsProps} />
      <TransactionTypeModal {...transactionTypeProps} />
      <QtyModal {...qtyProps} />
    </>
  );
};
