import React, { useContext, useEffect } from "react";
import ApplicationContext from "../../../context/ApplicationContext";
import {
  Button,
  Card,
  CardHeader,
  Form,
  ListGroup,
  Modal,
} from "react-bootstrap";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { ICustomerProps } from "./ICustomerProps";
import { ICustomer } from "../../../types/ICustomer";
import { Table } from "semantic-ui-react";
import { GenerateGUID } from "../../../helpers/Helper";
import { ITelephone } from "../../../types/ITelephone";
import { getTelephoneData } from "../../../services/Service";
import { IQtyProps } from "./IQtyProps";

export const QtyModal: React.FC<IQtyProps> = (
  props: IQtyProps
): JSX.Element => {
  const context = useContext(ApplicationContext);

  const [qty, setQty] = React.useState(1);

  function closeModal() {
    props.close();
  }

  useEffect(() => {
    setQty(props.row.qty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.row]);

  const customerModal = (): JSX.Element => {
    return (
      <>
        <Modal show={props.show} onHide={() => closeModal()}>
          <Modal.Header closeButton>
            <Modal.Title>Qty</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <div>
                <div
                  className="input-group w-auto align-items-center"
                  style={{
                    height: "100%",
                    padding: "15px",
                    marginLeft: "auto",
                    marginRight: "0",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    fontSize: 36,
                  }}
                >
                  <input
                    type="button"
                    value="-"
                    className="button-minus border rounded-circle  icon-shape icon-sm mx-1 "
                    data-field="quantity"
                    onClick={() => (qty > 1 ? setQty(qty - 1) : setQty(1))}
                  ></input>
                  <input
                    type="number"
                    step="1"
                    max="100"
                    value={qty}
                    name="quantity"
                    className="quantity-field border-0 text-center w-25"
                  ></input>
                  <input
                    type="button"
                    value="+"
                    className="button-plus border rounded-circle icon-shape icon-sm "
                    data-field="quantity"
                    onClick={() => setQty(qty + 1)}
                  ></input>
                </div>
              </div>
            </>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <div className="row">
              <div className="col">
                <Button
                  variant="secondary"
                  onClick={() => props.close()}
                  style={{ width: 100, height: 50 }}
                >
                  Cancel
                </Button>
              </div>
            </div>
            <div className="mr-auto"></div>
            <div>
              <Button
                className="btn btn-success btn-lg"
                onClick={() => updateQty()}
                style={{ width: 150, height: 50 }}
              >
                Update
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  function updateQty() {
    context.Receipt.setRowQty(props.row.uuid, qty);
    props.close();
  }

  return (
    <>
      <>{customerModal()}</>
    </>
  );
};
