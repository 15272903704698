export interface IStyleSettings {
  id: number;
  uud: string;
  menuButtonWidth: number;
  menuButtonHeight: number;
  menuButtonFontSize: number;
  menuButtonInstructionWidth: number;
  menuButtonInstructionHeight: number;
  menuButtonInstructionFontSize: number;
  receiptWidth: number;
  receiptFontSize: number;
}

export const defaultStyleSettings: IStyleSettings = {
  id: 0,
  uud: "",
  menuButtonWidth: 0,
  menuButtonHeight: 0,
  menuButtonFontSize: 0,
  menuButtonInstructionWidth: 0,
  menuButtonInstructionHeight: 0,
  menuButtonInstructionFontSize: 0,
  receiptWidth: 0,
  receiptFontSize: 0,
};
