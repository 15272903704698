import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.css";

import ApplicationContext from "../../context/ApplicationContext";
import { IProductOptionProps } from "./IProductOptionProps";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";

import { IOption } from "../../types/IOption";
import { IVariation } from "../../types/IVariation";
import { ICommonOption } from "../../types/ICommonOption";
import { IExtra } from "../../types/IExtra";
import { IReceiptRow } from "../../types/IReceiptRow";
import { IProduct } from "../../types/IProduct";

import backImage from "../../images/backArrow.48.png";
import { Table } from "semantic-ui-react";
import { GenerateGUID } from "../../helpers/Helper";
import { ITableData } from "../../types/ITableData";

const defaultTableData: ITableData = {
  id: 0,
  uuid: "",
  description: "",
  price: 0,
  variationId: 0,
  extraId: 0,
  commonId: 0,
  notes: "",
  instructionId: 0,
};

export const ProductOptions: React.FC<IProductOptionProps> = (
  props: IProductOptionProps
): JSX.Element => {
  const context = useContext(ApplicationContext);

  const [showExtras, setShowExtras] = useState(false);
  const [showVariations, setShowVariations] = useState(false);
  const [showOptions, setShowOptions] = useState(true);
  const [notes, setNotes] = useState("");
  const [refresh, setRefresh] = React.useState("");

  const defaultOptions: IOption[] = [];
  const defaultVariations: IVariation[] = [];
  const defaultExtraOptions: IExtra[] = [];
  const defaultCommonOptions: ICommonOption[] = [];

  const moveToEndVariationsRef = useRef<null | HTMLDivElement>(null);
  const moveToEndExtrasRef = useRef<null | HTMLDivElement>(null);
  const moveToEndOptionsRef = useRef<null | HTMLDivElement>(null);

  const [selectedRow, setSelectedRow] = React.useState(defaultTableData);
  const [selectedOptionId, setSelectedOptionId] = React.useState(0);

  const customStyle = context.Configuration.getSettings().styleSettings;

  const product: IProduct = {
    id: 0,
    parentId: 0,
    description: "",
    position: 0,
    isCategory: false,
    price: 0,
    qty: 0,
    uuid: "",
    imageUrl: "",
    textColour: "",
    buttonColour: "",
    zone: 0,
    barcode: "",
  };

  const defaultReceipt: IReceiptRow = {
    id: -1,
    product: product,
    variations: [],
    extras: [],
    commonOptions: [],
    notes: "",
    instructions: [],
    description: [],
    price: 0,
    qty: 1,
    isDiscount: false,
    options: [],
    uuid: GenerateGUID(),
  };

  const [options, setOptions] = React.useState(defaultOptions);
  const [variations, setVariations] = React.useState(defaultVariations);
  const [extras, setExtras] = React.useState(defaultExtraOptions);
  const [commonOptions, setCommonOptions] =
    React.useState(defaultCommonOptions);

  const [selectedOptions, setSelectedOptions] = React.useState(defaultOptions);
  const [selectedVariations, setSelectedVariations] =
    React.useState(defaultVariations);
  const [selectedExtras, setSelectedExtras] =
    React.useState(defaultExtraOptions);
  const [selectedCommonOptions, setSelectedCommonOptions] =
    React.useState(defaultCommonOptions);

  const [tableData, setTableData] = React.useState<ITableData[]>([]);
  const [receipt, setReceipt] = React.useState(defaultReceipt);

  useEffect(() => {
    moveToEndVariationsRef.current?.scrollIntoView({ behavior: "smooth" });
    moveToEndExtrasRef.current?.scrollIntoView({ behavior: "smooth" });
    moveToEndOptionsRef.current?.scrollIntoView({ behavior: "smooth" });
  });

  function filterOptions() {
    const selectedId = context.Data.getSelectedProductId();
    const allOptions = context.BranchMenu.getMenu().options;

    let filteredOptions: IOption[] = [];
    for (let i = 0; i < allOptions.length; i++) {
      if (allOptions[i].productId == selectedId) {
        filteredOptions.push(allOptions[i]);
      }
    }
    setOptions(filteredOptions);
  }

  function filterVariations() {
    setVariations(context.BranchMenu.getMenu().variations);
  }

  function filterExtras() {
    setExtras(context.BranchMenu.getMenu().extras);
  }

  function update() {
    filterOptions();
    filterExtras();
    filterVariations();
  }

  function clearViews() {
    setShowExtras(false);
    setShowVariations(false);
  }

  function showOptionsView() {
    console.log("i get here");
    clearViews();
    update();
  }

  useEffect(() => {
    update();
  }, [context.Data.getSelectedProductId()]);

  function getProduct() {
    return context.BranchMenu.getMenu().products.find((element) => {
      return element.id === context.Data.getSelectedProductId();
    });
  }

  function getProductDescription() {
    const p = getProduct();

    if (p === undefined) {
      return "";
    }

    return p.description + " (£" + p?.price.toFixed(2) + ")";
  }

  function showProductView() {
    props.showProducts(context.Data.getSelectedProductId());
  }

  function showFilterVariations(id: number) {
    const variations = context.BranchMenu.getMenu().variations;

    let filteredVariations: IVariation[] = [];
    for (let i = 0; i < variations.length; i++) {
      if (variations[i].optionId == id) {
        filteredVariations.push(variations[i]);
      }
    }

    setVariations(filteredVariations);

    let tableData: ITableData[] = [];
    for (let i = 0; i < selectedVariations.length; i++) {
      if (selectedVariations[i].optionId == id) {
        const v = selectedVariations[i];
        const d: ITableData = {
          id: i,
          description: v.description,
          price: v.price,
          variationId: v.id,
          extraId: 0,
          commonId: 0,
          notes: "",
          instructionId: 0,
          uuid: GenerateGUID(),
        };
        tableData.push(d);
      }
    }
    setSelectedOptionId(id);
    setTableData(tableData);
    setShowVariations(true);
    setRefresh(GenerateGUID());
  }

  function showFilterExtras(id: number) {
    const extras = context.BranchMenu.getMenu().extras;

    let filteredExtras: IExtra[] = [];
    for (let i = 0; i < extras.length; i++) {
      if (extras[i].optionId == id) {
        filteredExtras.push(extras[i]);
      }
    }
    setExtras(filteredExtras);

    let tableData: ITableData[] = [];
    for (let i = 0; i < selectedExtras.length; i++) {
      if (selectedExtras[i].optionId == id) {
        const extra = selectedExtras[i];
        const d: ITableData = {
          id: i,
          description: extra.description,
          price: extra.price,
          variationId: -1,
          extraId: extra.id,
          commonId: -1,
          notes: "",
          instructionId: 0,
          uuid: extra.uuid,
        };
        tableData.push(d);
      }
    }
    setTableData(tableData);
    setSelectedOptionId(id);
    setShowExtras(true);
    setRefresh(GenerateGUID());
  }

  function onVariationClick(variation: IVariation) {
    let deepCopy: IVariation = JSON.parse(JSON.stringify(variation));

    let data: ITableData[] = tableData;

    const d: ITableData = {
      id: tableData.length + 1,
      description: deepCopy.description,
      price: deepCopy.price,
      variationId: deepCopy.id,
      extraId: 0,
      commonId: 0,
      notes: "",
      instructionId: 0,
      uuid: GenerateGUID(),
    };
    data.push(d);

    setTableData(data);
    setRefresh(GenerateGUID());
  }

  function getProductById(id: number) {
    return context.BranchMenu.getMenu().products.find((element) => {
      return element.id === id;
    });
  }

  function onExtraClick(extra: IExtra) {
    let deepCopy: IExtra = JSON.parse(JSON.stringify(extra));

    let data: ITableData[] = tableData;

    const d: ITableData = {
      id: tableData.length + 1,
      description: deepCopy.description,
      price: deepCopy.price,
      variationId: 0,
      extraId: deepCopy.id,
      commonId: 0,
      notes: "",
      instructionId: 0,
      uuid: GenerateGUID(),
    };
    data.push(d);

    setTableData(data);
    setRefresh(GenerateGUID());
  }

  function getAllSelectedOptions(optionId: number) {
    let allOptions = "";
    for (let i = 0; i < selectedVariations.length; i++) {
      if (selectedVariations[i].optionId === optionId) {
        allOptions +=
          selectedVariations[i].description +
          " (" +
          selectedVariations[i].price.toFixed(2) +
          "),";
      }
    }
    for (let i = 0; i < selectedExtras.length; i++) {
      if (selectedExtras[i].optionId === optionId) {
        allOptions +=
          selectedExtras[i].description +
          " (" +
          selectedExtras[i].price.toFixed(2) +
          "),";
      }
    }
    return allOptions;
  }

  const OptionsView = (): JSX.Element => (
    <>
      <div>
        <div>
          <div>
            <Row>
              <Col xs lg="2">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-lg"
                  onClick={showProductView}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    fill="currentColor"
                    className="bi bi-box-arrow-in-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708z"
                    />
                  </svg>
                </button>
              </Col>
              <Col md="auto" style={{ fontSize: 24, padding: 5 }}>
                {getProductDescription()}
              </Col>
              <Col xs lg="2"></Col>
            </Row>
            <hr />
          </div>
          {options.map((option) => (
            <div style={{ width: "100%", padding: 5 }}>
              <div>
                <Card>
                  <Card.Body>
                    <Card.Title>
                      {option.description}{" "}
                      {" (£" + option.price.toFixed(2) + ")"}
                    </Card.Title>
                    <Card.Text>{getAllSelectedOptions(option.id)}</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <Row>
                      <Col md="auto" style={{ padding: 0 }}>
                        <Button
                          onClick={() => showFilterVariations(option.id)}
                          style={{
                            width: "130px",
                            height: "43px",
                            fontSize: "17px",
                          }}
                        >
                          Variations
                        </Button>{" "}
                        <Button
                          onClick={() => showFilterExtras(option.id)}
                          style={{
                            width: "130px",
                            height: "43px",
                            fontSize: "17px",
                          }}
                        >
                          Extras
                        </Button>
                      </Col>
                    </Row>
                  </Card.Footer>
                </Card>
              </div>
            </div>
          ))}
        </div>
      </div>

      <br></br>
      <div className="d-grid gap-2" style={{ width: "100%" }}>
        <Button
          variant="success"
          style={{ width: "200px", height: "50px", fontSize: "20px" }}
          onClick={() => onAddClick()}
        >
          Add all options
        </Button>
      </div>
    </>
  );

  function onAddClick() {
    const product = getProductById(context.Data.getSelectedProductId());

    if (product === undefined) {
      return;
    }

    let description: string[] = [];
    let total = product.price;

    options.forEach((o) => {
      //description
      description.push(o.description + " (£" + o.price.toFixed(2) + ")");

      //variations
      const v = selectedVariations.filter((v) => v.optionId === o.id);
      v.forEach((element) => {
        let d = "   ";
        d += element.description;

        if (element.price > 0) {
          d += " (£" + element.price.toFixed(2) + ")";
        }

        //description.push(d);
        total = total + element.price;
      });

      //extras
      const e = selectedExtras.filter((d) => d.optionId === o.id);
      e.forEach((element) => {
        // description.push(
        //   "   " + element.description + "(£" + element.price.toFixed(2) + ")"
        // );
        total = total + element.price;
      });

      //common options
      selectedCommonOptions.forEach((element) => {
        // description.push(
        //   "   " + element.description + "(£" + element.price.toFixed(2) + ")"
        // );
        total = total + element.price;
      });

      total = total + o.price;
    });

    //add to receipt
    const receiptRow: IReceiptRow = {
      id: context.Receipt.getRows().length + 1,
      product: product,
      variations: selectedVariations,
      extras: selectedExtras,
      commonOptions: [],
      notes: notes,
      instructions: [],
      description: description,
      price: total,
      qty: 1,
      isDiscount: false,
      options: options,
      uuid: GenerateGUID(),
    };

    context.Receipt.addRow(receiptRow);
    props.triggerRefresh();
  }

  const variationView = (): JSX.Element => {
    return (
      <Modal
        show={showVariations}
        onHide={() => setShowVariations(false)}
        dialogClassName="my-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Variations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div>
              <div style={{ padding: "10px" }}>
                <div className="row">
                  <div style={{ width: "59%" }}>
                    <div style={{ height: "68vh" }}>
                      <div
                        style={{
                          height: "68vh",
                          overflowY: "auto",
                        }}
                      >
                        {variations.map((v) => (
                          <Button
                            className="mr-1"
                            variant="outline-primary bt-lg"
                            style={{
                              width: customStyle.menuButtonInstructionWidth,
                              height: customStyle.menuButtonInstructionHeight,
                              //backgroundColor: "lightblue",
                              borderRadius: "10px",
                              padding: "10px",
                            }}
                            onClick={() => onVariationClick(v)}
                          >
                            {v.description} <br /> £{v.price.toFixed(2)}
                          </Button>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div style={{ padding: "3px" }}></div>
                  <div style={{ width: "39%" }}>
                    <div>
                      <div style={{ paddingBottom: 10 }}>
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-lg"
                          disabled={true}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            fill="currentColor"
                            className="bi bi-clipboard-minus"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5.5 9.5A.5.5 0 0 1 6 9h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5"
                            />
                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                          </svg>
                        </button>{" "}
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-lg"
                          onClick={onDeleteClick}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            fill="currentColor"
                            className="bi bi-clipboard-x"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708"
                            />
                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                          </svg>
                        </button>
                      </div>
                      <div style={{ overflowY: "auto", maxHeight: "55vh" }}>
                        <Table unstackable selectable color={"blue"}>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Description</Table.HeaderCell>
                              <Table.HeaderCell>Price</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {tableData.map((data) => (
                              <Table.Row
                                onClick={() => {
                                  onRowClicked(data);
                                }}
                                active={isSelected(data.uuid)}
                              >
                                <Table.Cell>{data.description}</Table.Cell>
                                <Table.Cell>{data.price.toFixed(2)}</Table.Cell>
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>
                        <div ref={moveToEndVariationsRef}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer className="modal-footer justify-content-between">
          <div className="mr-auto"></div>
          <div>
            {/* <Button
              variant="secondary"
              onClick={(e) => setShowVariations(false)}
            >
              Cancel
            </Button>
            <> </> */}
            <Button
              variant="success"
              className="modal-padding"
              onClick={() => addVariationCompleteClick()}
              style={{ height: 50, width: 150, fontSize: 16 }}
            >
              Update Order
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  function addVariationCompleteClick() {
    let currentVariations = selectedVariations;
    const selectedOption = selectedOptionId;

    //delete all for currentVariations where option is selected.
    const toDeleteVariations = currentVariations.filter(
      (d) => d.optionId === selectedOptionId
    );
    for (let i = 0; i < toDeleteVariations.length; i++) {
      for (let j = 0; j < selectedVariations.length; j++) {
        if (selectedVariations[j].uuid === toDeleteVariations[i].uuid) {
          selectedVariations.splice(j, 1);
        }
      }
    }

    let v: IVariation[] = selectedVariations;
    for (let i = 0; i < tableData.length; i++) {
      const d = variations.filter((d) => d.id === tableData[i].variationId);
      v.push(d[0]);
    }
    setSelectedVariations(v);
    setShowVariations(false);
  }

  function isSelected(uuid: string) {
    if (selectedRow.uuid === uuid) {
      return true;
    }
    return false;
  }

  function onRowClicked(selectedRow: ITableData) {
    setSelectedRow(selectedRow);
  }

  function onDeleteClick() {
    let tData = tableData;

    let idx = -1;

    for (let i = 0; i < tData.length; i++) {
      if (tData[i].uuid == selectedRow.uuid) {
        idx = i;
      }
    }

    if (idx > -1) {
      tData.splice(idx, 1);
    }

    setTableData(tData);
    setRefresh(GenerateGUID());
  }

  const extraView = (): JSX.Element => {
    return (
      <>
        <Modal
          show={showExtras}
          onHide={() => setShowExtras(false)}
          dialogClassName="my-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Extras</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <div>
                <div style={{ padding: "20px" }}>
                  <div className="row">
                    <div style={{ width: "59%" }}>
                      <div style={{ height: "68vh" }}>
                        <div
                          style={{
                            height: "68vh",
                            overflowY: "auto",
                          }}
                        >
                          {extras.map((v) => (
                            <Button
                              className="mr-1"
                              variant="outline-primary bt-lg"
                              style={{
                                width: customStyle.menuButtonInstructionWidth,
                                height: customStyle.menuButtonInstructionHeight,
                                //backgroundColor: "lightblue",
                                borderRadius: "10px",
                                padding: "10px",
                              }}
                              onClick={() => onExtraClick(v)}
                            >
                              {v.description} <br /> £{v.price.toFixed(2)}
                            </Button>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div style={{ padding: "3px" }}></div>
                    <div style={{ width: "39%" }}>
                      <div>
                        <div style={{ paddingBottom: 10 }}>
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-lg"
                            disabled={true}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="48"
                              height="48"
                              fill="currentColor"
                              className="bi bi-clipboard-minus"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M5.5 9.5A.5.5 0 0 1 6 9h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5"
                              />
                              <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                              <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                            </svg>
                          </button>{" "}
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-lg"
                            onClick={onDeleteClick}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="48"
                              height="48"
                              fill="currentColor"
                              className="bi bi-clipboard-x"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708"
                              />
                              <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                              <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                            </svg>
                          </button>
                        </div>
                        <div style={{ overflowY: "auto", maxHeight: "55vh" }}>
                          <Table unstackable selectable color={"blue"}>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>Description</Table.HeaderCell>
                                <Table.HeaderCell>Price</Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {tableData.map((data) => (
                                <Table.Row
                                  onClick={() => {
                                    onRowClicked(data);
                                  }}
                                  active={isSelected(data.uuid)}
                                >
                                  <Table.Cell>{data.description}</Table.Cell>
                                  <Table.Cell>
                                    {data.price.toFixed(2)}
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </Table.Body>
                          </Table>
                          <div ref={moveToEndVariationsRef}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </Modal.Body>
          <Modal.Footer className="modal-footer justify-content-between">
            <div className="mr-auto"></div>
            <div>
              {/* <Button
              variant="secondary"
              onClick={(e) => setShowVariations(false)}
            >
              Cancel
            </Button>
            <> </> */}
              <Button
                variant="success"
                className="modal-padding"
                onClick={() => addExtraCompleteClick()}
                style={{ height: 50, width: 150, fontSize: 16 }}
              >
                Update Order
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  function addExtraCompleteClick() {
    let currentExtras = selectedExtras;
    const selectedOption = selectedOptionId;

    //delete all for currentVariations where option is selected.
    const toDelete = currentExtras.filter(
      (d) => d.optionId === selectedOptionId
    );
    for (let i = 0; i < toDelete.length; i++) {
      for (let j = 0; j < selectedExtras.length; j++) {
        if (selectedExtras[j].uuid === toDelete[i].uuid) {
          selectedExtras.splice(j, 1);
        }
      }
    }

    let e: IExtra[] = selectedExtras;
    for (let i = 0; i < tableData.length; i++) {
      const d = extras.filter((d) => d.id === tableData[i].extraId);
      e.push(d[0]);
    }

    setSelectedExtras(e);
    setShowExtras(false);
  }

  return (
    <div>
      {OptionsView()}
      {variationView()}
      {extraView()}
    </div>
  );
};
